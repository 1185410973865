import {fetchData} from "../use-fetch.js"

export default {
    async loadServices(context, payload){
        let clientId = payload.clientId;
        let serviceId = payload.serviceId

        const responseData = await fetchData(`/v1/dashboard/appointment/new?service=${serviceId}&client=${clientId}`, {});

        if(responseData.service) {
            context.commit('setServices', responseData.service);

            let selectedService = '';
            responseData.service.forEach(function (item) {
                if (item.selected === true) {
                    selectedService = item.key;
                }
            });

            context.commit("setSelectedService", selectedService);
        }

        if(responseData.client && responseData.client.length > 0){
            context.commit('setClients', responseData.client);
        }

        if(responseData.patient && responseData.patient.length > 0){
            context.commit('setPatients', responseData.patient)
        }
    },

    async getScheduleAttemptCustomFields(context, taskId) {
        const responseData = await fetchData(`/v1/dashboard/appointment/getScheduleAttemptCustomFields?taskId=${taskId}`);

        const scheduleAttemptCustomFields = [];

        if(responseData.formStructure) {
            responseData.formStructure.forEach(function (form) {
                const editForm = {
                    type: form.type,
                    name: form.name,
                    label: form.label,
                    placeholder: form.placeholder,
                    value: form.value,
                    options: form.options,
                    taskId: taskId
                }
                scheduleAttemptCustomFields.push(editForm);
            });
        }

        context.commit("setScheduleAttemptCustomFields", scheduleAttemptCustomFields);
    },

    async saveScheduleAttemptCustomFields(context, payload) {

        console.log('saveScheduleAttemptCustomFields', payload);

        await fetchData(`/v1/dashboard/appointment/saveScheduleAttemptCustomFields`, {
            method: 'POST',
            body: JSON.stringify(payload),
        });
    }

}
